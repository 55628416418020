"use client"
import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import logo from '@/public/logo.webp'
const Navbar: React.FC<{
  categories?: any[]
}> = (
  {
    categories
  }
) => {
    const [activeMenu, setActiveMenu] = useState<any>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMouseEnter = (index: number) => {
      setActiveMenu(index);
    };

    const handleMouseLeave = () => {
      setActiveMenu(null);
    };

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    return (
      <nav className="bg-appleGray text-white text-xs shadow-md fixed w-full z-50">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center h-16 justify-between">
            <div className="flex items-center h-full">
              <div className="shrink-0">
                <Link href="/">
                  <Image
                    className="h-8 w-auto"
                    src={logo} // Replace with your logo
                    alt="Apple Logo"
                  />
                </Link>
              </div>
              <div className="hidden lg:flex lg:items-center ml-10 h-full">
                {categories?.slice(0, 8).map((item: any, index: number) => (
                  <div
                    key={item.category_id}
                    onMouseEnter={() => handleMouseEnter(item.category_id)}
                    onMouseLeave={handleMouseLeave}
                    className="relative flex items-center h-full cursor-pointer px-5 text-sm"
                  >
                    <Link
                      href={"#"}
                      className={`hover:text-gray-400 ${activeMenu === item.category_id ? "text-gray-400" : ""} w-full text-center`}
                    >
                      {item.category_name.replace("Conversion", "").trim()}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="hidden md:flex lg:hidden md:items-center ml-10 h-full">
                {categories?.slice(0, 6).map((item: any, index: number) => (
                  <div
                    key={item.category_id}
                    onMouseEnter={() => handleMouseEnter(item.category_id)}
                    onMouseLeave={handleMouseLeave}
                    className="relative flex items-center h-full cursor-pointer px-5 text-sm"
                  >
                    <Link
                      href={"#"}
                      className={`hover:text-gray-400 ${activeMenu === item.category_id ? "text-gray-400" : ""} w-full text-center`}
                    >
                      {item.category_name.replace("Conversion", "").trim()}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {/* Hamburger menu button for mobile, positioned to the right */}
            <div className="md:hidden flex items-center">
              <button
                aria-label="navMenu"
                name="navMenu"
                onClick={toggleMenu}
                className="text-gray-400 hover:text-white focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={
                      isMenuOpen
                        ? "M6 18L18 6M6 6l12 12"
                        : "M4 6h16M4 12h16M4 18h16"
                    }
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Mobile menu for categories */}
        {isMenuOpen && (
          <div className="md:hidden bg-appleGray shadow-lg z-50">
            <div className="px-4 py-2">
              {categories?.slice(0, 8).map((item: any) => (
                <Link
                  key={item.category_id}
                  href={`/resources/categories?category_slug=${item.category_slug}`}
                  className="block py-2 px-4 hover:bg-gray-700"
                >
                  {item.category_name.replace("Conversion", "").trim()}
                </Link>
              ))}
            </div>
          </div>
        )}
        {/* Full-width menu, shown only when hovering over a menu item */}
        {activeMenu && (
          <div
            className="absolute w-full bg-appleGray bg-opacity-95 shadow-lg z-50"
            onMouseEnter={() => handleMouseEnter(activeMenu)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="max-w-7xl mx-auto px-4 py-6 grid grid-cols-4 gap-4">
              {categories
                ?.filter((item) => item.category_id === activeMenu)[0]
                .calculators.slice(0, 8)
                .map((option: any, index: number) =>
                  index === 7 ? (
                    <Link
                      key={index}
                      href={`/resources/categories?category_slug=${option.category_slug}`}
                      className="hover:text-blue-400 underline transition-colors duration-300"
                    >
                      {"View All"}
                    </Link>
                  ) : (
                    <Link
                      key={index}
                      href={`/${option.calculator_slug}`}
                      className="hover:text-gray-400"
                    >
                      {option.calculator_name}
                    </Link>
                  )
                )}
            </div>
          </div>
        )}
      </nav>
    );
  };

export default Navbar;
