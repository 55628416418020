"use client";
import React from "react";
import Link from "next/link";
import Image from "next/image";
import logo from "@/public/logo.webp";

const Footer: React.FC = () => {
  return (
    <footer className="text-xs text-white bg-appleGray">
      <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-5">
          {/* Logo and Description */}
          <div>
            <Link href="/">
              <Image
                src={logo}
                alt="Apple Logo"
                className="w-auto h-10 mb-4"
                priority
              />
            </Link>
            <p className="text-gray-400">
              Providing reliable tools and calculators to make your life easier.
            </p>
          </div>
          {/* Quick Links */}
          <div>
            <h3 className="mb-4 text-lg font-semibold text-white">
              Quick Links
            </h3>
            <ul className="space-y-2">
              <li>
                <Link href="/" className="hover:text-gray-400">
                  Home
                </Link>
              </li>
              <li>
                <Link
                  href="/resources/categories"
                  className="hover:text-gray-400"
                >
                  Explore
                </Link>
              </li>
              <li>
                <Link
                  href="/resources/privacy-policy"
                  className="hover:text-gray-400"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  href="/resources/terms-of-service"
                  className="hover:text-gray-400"
                >
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>
          {/* Featured Tools */}
          <div>
            <h3 className="mb-4 text-lg font-semibold text-white">
              Featured Tools
            </h3>
            <ul className="space-y-2">
              <li>
                <Link
                  href="/inch-to-centimeter"
                  className="hover:text-gray-400"
                >
                  Inch to Centimeters
                </Link>
              </li>
              <li>
                <Link
                  href="/celsius-to-fahrenheit"
                  className="hover:text-gray-400"
                >
                  Celsius to Fahrenheit
                </Link>
              </li>
              <li>
                <Link href="/kilogram-to-gram" className="hover:text-gray-400">
                  Kilogram to Gram
                </Link>
              </li>
              <li>
                <Link
                  href="/liter-to-milliliter"
                  className="hover:text-gray-400"
                >
                  Liter to Milliliter
                </Link>
              </li>
            </ul>
          </div>
          {/* Categories */}
          <div>
            <h3 className="mb-4 text-lg font-semibold text-white">
              Categories
            </h3>
            <ul className="space-y-2">
              <li>
                <Link
                  href="/resources/categories?category_slug=length-conversion"
                  className="hover:text-gray-400"
                >
                  Length Conversion
                </Link>
              </li>
              <li>
                <Link
                  href="/resources/categories?category_slug=temperature-conversion"
                  className="hover:text-gray-400"
                >
                  Temperature Conversion
                </Link>
              </li>
              <li>
                <Link
                  href="/resources/categories?category_slug=weight-conversion"
                  className="hover:text-gray-400"
                >
                  Weight Conversion
                </Link>
              </li>
              <li>
                <Link
                  href="/resources/categories?category_slug=volume-conversion"
                  className="hover:text-gray-400"
                >
                  Volume Conversion
                </Link>
              </li>
            </ul>
          </div>
          {/* Social Media */}
          <div>
            <h3 className="mb-4 text-lg font-semibold text-white">Follow Us</h3>
            <ul className="flex space-x-4">
              <li>
                <Link
                  href="https://www.facebook.com/profile.php?id=61572245796810"
                  target="_blank"
                  className="hover:text-gray-400"
                >
                  <span className="sr-only">Facebook</span>
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82V14.708h-3.1v-3.617h3.1V8.36c0-3.066 1.871-4.737 4.604-4.737 1.31 0 2.438.097 2.766.141v3.204h-1.898c-1.491 0-1.78.707-1.78 1.746v2.291h3.566l-.465 3.617h-3.101V24h6.087c.731 0 1.325-.593 1.325-1.324V1.325C24 .593 23.407 0 22.675 0z" />
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  href="https://www.linkedin.com/company/alltoolshere/"
                  target="_blank"
                  className="hover:text-gray-400"
                >
                  <span className="sr-only">LinkedIn</span>
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.026-3.036-1.852-3.036-1.855 0-2.141 1.445-2.141 2.938v5.667H9.344V9h3.414v1.561h.049c.476-.9 1.637-1.852 3.372-1.852 3.604 0 4.268 2.37 4.268 5.455v6.288zM5.337 7.433a2.073 2.073 0 1 1 0-4.146 2.073 2.073 0 0 1 0 4.146zM6.955 20.452H3.72V9h3.234v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.226.792 24 1.771 24h20.451C23.207 24 24 23.226 24 22.271V1.729C24 .774 23.207 0 22.225 0z" />
                  </svg>
                </Link>
              </li>
              {/* Add other social media icons */}
            </ul>
          </div>
        </div>
        {/* Copyright */}
        <div className="pt-4 mt-8 text-center text-gray-500 border-t border-gray-700">
          © {new Date().getFullYear()} All Tools Here. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
